import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';


const Buyer = () => (
    <Layout>
    <Helmet   
      htmlAttributes={{
      lang: 'en',
    }}>
    <title lang="en">Buy Your Home With On The Block Real Estate International</title>
    <meta name="description" content="On The Block Has Been Serving California & Out Of State 
    Home Buyers For Over 20+ years, Connect With Us Today!" lang="en"/>
    </Helmet>
      <section className="pt-20 md:pt-40 BuyerBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Ready To Buy?<br/>
               Here's Our Checklist
            </h1>
        </div>
      </section>
  
      <section className="pt-5 px-5">
        <div className="container pt-5 pb-5 mx-auto bg-gray-200 rounded-lg">
            <h1 className="text-center text-2xl lg:text-3xl font-regular leading-none">
                Things You Must Know About<br/>Buying Real Estate
            </h1>        
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #1</h2>
            <p className="text-grey-900">
            If you are not in a property within 90 days — either you or your agent are not serious about buying a home.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #2</h2>
            <p className="text-grey-900">
                Try to be flexible; There is a big difference between wanting or needing something.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #3</h2>
            <p className="text-grey-900">
                Internet websites can be inaccurate. Use com for best info/results over other sites.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #4</h2>
            <p className="text-grey-900">
            Work with your agent and loan officer to get loan approval first. Do not lose your home.
            because financing is not ready yet.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #5</h2>
            <p className="text-grey-900">
            Keep your options open by writing multiple offers on multiple properties that will work for you.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #6</h2>
            <p className="text-grey-900">
            There is no perfect property – even if built from scratch – be open to possibilities, not rigid to all.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker ">Fact #7</h2>
            <p className="text-grey-900">
            Use an agent experienced with buyers – a Good Buyer Agent Can Help You Get That Dream Home.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker ">Fact #8</h2>
            <p className="text-grey-900">
            Read all reports/disclosures/paperwork about the property carefully and have your agent explain it all.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker ">Fact #9</h2>
            <p className="text-grey-900">
            If you are not in a property within 90 days — either you or your agent are not serious about buying a home.
            </p>
          </div>
        </div>
      </section>
  
      <section className="container mx-auto my-10 py-20 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Are You Prequalified?</h3>
        <p className="mt-8 mb-5 text-xl font-light">
          We are available 24/7 to answer any questions, call us or feel free to send us a message
          through our chat.
        </p>
        <a href="tel:562-972-1390">
          <Button size="l">
            Call Now
          </Button>
        </a>
      </section>
    </Layout>
  );

export default Buyer;